<template>
  <div class="flex">
    <LogoutVariant />
  </div>
</template>

<script>
import LogoutVariant from "vue-material-design-icons/LogoutVariant.vue"

export default {
  name: "LogoutVariantIcon",
  components: {
    LogoutVariant,
  },
}
</script>
