import axios from "axios"
import { jwtDecrypt } from "../../shared/jwtHelper"
import { tokenAlive } from "../../shared/jwtHelper"

if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = "http://localhost:3030"
}

const state = () => ({
  loginApiStatus: "",
  logOut: false,
  authData: {
    token: "",
    accessToken: "",
    refreshToken: "",
    tokenExp: "",
    userId: "",
    userName: "",
    email: "",
  },
  userProfile: {
    id: 0,
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    defaultWeekWorktimeInMinutes: "",
    defaultWeekWorkdays: "",
    settings: {},
  },
})

const getters = {
  getLoginApiStatus(state) {
    return state.loginApiStatus
  },
  getLogout(state) {
    return state.logOut
  },
  getAuthData(state) {
    return state.authData
  },
  isTokenActive(state) {
    if (!state.authData.tokenExp) {
      return false
    }
    return tokenAlive(state.authData.tokenExp)
  },
  getUserProfile(state) {
    return state.userProfile
  },
}

const actions = {
  async checkStoredToken({ commit }) {
    const access_token = localStorage.getItem("access_token")
    if (access_token != null) {
      const data = {
        access_token,
        refresh_token: "",
      }
      commit("saveTokenData", data)
      localStorage.setItem("isAuthenticated", "true")
      commit("setLoginApiStatus", "success")
    }
  },
  async loginApi({ commit, dispatch }, payload) {
    // async loginApi({ commit }, payload) {
    const response = await axios
      .post("/api/auth/login", payload, {
        withCredentials: false,
        credentials: "include",
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data && !response.data.bla) {
      // console.log('saveTokenData', response)
      commit("saveTokenData", response.data)
      localStorage.setItem("isAuthenticated", "true")
      commit("setLoginApiStatus", "success")
    } else {
      commit("setLoginApiStatus", "failed")
      dispatch("userLogout")
      commit("setLogout", true)
      localStorage.removeItem("isAuthenticated")
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
    }
  },
  async userLogout({ commit }) {
    const response = await axios
      .get("/api/logout", {
        withCredentials: false,
        credentials: "include",
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setLogout", true)
      localStorage.removeItem("isAuthenticated")
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
    } else {
      commit("setLogout", true)
    }

    commit("clearTokenData")
    commit("setLoginApiStatus", "")
  },
  async userProfile({ commit, dispatch, state }) {
    const response = await axios
      .post(
        "/api/user-profile",
        { email: state.authData.email },
        {
          withCredentials: false,
          credentials: "include",
          headers: {
            "x-access-token": localStorage.getItem("access_token"),
          },
        }
      )
      .catch((err) => {
        dispatch("userLogout")
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setUserProfile", response.data)
    }
  },
}

const mutations = {
  clearTokenData(state) {
    state.authData = {
      token: "",
      accessToken: "",
      refreshToken: "",
      tokenExp: "",
      userId: "",
      userName: "",
      email: "",
    }
  },
  saveTokenData(state, data) {
    if (data.access_token != "undefined" && data.access_token != "null") {
      localStorage.setItem("access_token", data.access_token)
      localStorage.setItem("refresh_token", data.refresh_token)

      const jwtDecodedValue = jwtDecrypt(data.access_token)
      const newTokenData = {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        tokenExp: jwtDecodedValue.exp,
        userId: jwtDecodedValue.sub,
        userName: jwtDecodedValue.userName,
        email: jwtDecodedValue.email,
      }
      state.authData = newTokenData
    }
  },
  setLoginApiStatus(state, data) {
    state.loginApiStatus = data
  },
  setLogout(state, payload) {
    state.logOut = payload
    const userProfile = {
      id: 0,
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      defaultWeekWorktimeInMinutes: "",
      defaultWeekWorkdays: "",
    }
    state.userProfile = userProfile
  },
  setUserProfile(state, data) {
    const userProfile = {
      id: data.id,
      lastName: data.last_name,
      firstName: data.first_name,
      email: data.email,
      phone: data.phone,
      userRole: data.user_role,
      defaultWeekWorktimeInMinutes: data.default_week_worktime_in_minutes,
      defaultWeekWorkdays: data.default_week_workdays,
      settings: data.settings,
    }
    state.userProfile = userProfile
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
