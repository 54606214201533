import axios from "axios"

if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = "http://localhost:3030"
}

const state = () => ({
  dateTypes: [],
})

const getters = {
  getDateTypes(state) {
    return state.dateTypes
  },
}

const actions = {
  async getApiDateTypes({ commit }) {
    const response = await axios
      .get("/api/dateTypes", {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("saveApiDateTypes", response.data)
    }
  },
}

const mutations = {
  saveApiDateTypes(state, data) {
    const iconList = [
      { name: "vacation", iconTitle: "vacation" },
      { name: "half_vacation", iconTitle: "halfVacation" },
      { name: "holiday", iconTitle: "holiday" },
      { name: "half_holiday", iconTitle: "halfHoliday" },
      { name: "illness", iconTitle: "illness" },
      { name: "workday", iconTitle: "workday" },
      { name: "half_workday", iconTitle: "halfWorkday" },
      { name: "break", iconTitle: "coffee" },
    ]
    const newData = []
    data.forEach((e) => {
      newData.push({
        ...e,
        icon: iconList.find((a) => a.name == e.title),
      })
    })
    state.dateTypes = newData
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
