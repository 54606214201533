<template>
  <div class="flex">
    <ArrowLeft />
  </div>
</template>

<script>
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue"

export default {
  name: "ArrowLeftIcon",
  components: {
    ArrowLeft,
  },
}
</script>
