<template>
  <div class="flex items-center">
    <input :id="randomId" type="checkbox" value="" :class="[defaultClasses]" />
    <label
      :for="randomId"
      :class="[defaultTextClasses, { hasmytooltip: tooltipText }]"
    >
      <span
        v-if="tooltipText"
        class="mytooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8"
      >
        {{ tooltipText }}
      </span>
      {{ text }}
      <IconSelector v-if="iconText" :iconText="iconText" />
    </label>
  </div>
</template>

<script>
import IconSelector from "../combinedIcons/IconSelector.vue"

export default {
  name: "TimeTrackingCheckbox",
  components: {
    IconSelector,
  },
  props: {
    text: String,
    iconText: String,
    tooltipText: String,
  },
  created() {
    this.randomId = this.uuidv4()
  },
  data() {
    return {
      randomId: "",
      defaultClasses:
        "w-6 h-6 text-blue-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600",
      defaultTextClasses:
        "ms-2 text-sm font-bold text-gray-900 dark:text-gray-300",
    }
  },
  methods: {
    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          +c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
        ).toString(16)
      )
    },
  },
}
</script>

<style scoped>
.mytooltip {
  visibility: hidden;
  position: absolute;
}
.hasmytooltip:hover .mytooltip {
  visibility: visible;
  z-index: 1000;
}
</style>
