<template>
  <div class="flex">
    <ArrowTopRight />
  </div>
</template>

<script>
import ArrowTopRight from "vue-material-design-icons/ArrowTopRight.vue"

export default {
  name: "ArrowTopRightIcon",
  components: {
    ArrowTopRight,
  },
}
</script>
