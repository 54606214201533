<template>
  <div class="flex">
    <BriefcaseIcon />
  </div>
</template>

<script>
import BriefcaseIcon from "vue-material-design-icons/Briefcase.vue"

export default {
  name: "WorkdayIcon",
  components: {
    BriefcaseIcon,
  },
}
</script>
