class LineFitter {
  constructor() {
    this.count = 0
    this.sumX = 0
    this.sumX2 = 0
    this.sumXY = 0
    this.sumY = 0
  }
  add(x, y) {
    this.count++
    this.sumX += x
    this.sumX2 += x * x
    this.sumXY += x * y
    this.sumY += y
  }
  project(x) {
    var det = this.count * this.sumX2 - this.sumX * this.sumX
    var offset = (this.sumX2 * this.sumY - this.sumX * this.sumXY) / det
    var scale = (this.count * this.sumXY - this.sumX * this.sumY) / det
    return offset + x * scale
  }
}

class SquareFitter {
  constructor() {
    this.count = 0
    this.sumX = 0
    this.sumX2 = 0
    this.sumX3 = 0
    this.sumX4 = 0
    this.sumY = 0
    this.sumXY = 0
    this.sumX2Y = 0
  }

  add(x, y) {
    this.count++
    this.sumX += x
    this.sumX2 += x * x
    this.sumX3 += x * x * x
    this.sumX4 += x * x * x * x
    this.sumY += y
    this.sumXY += x * y
    this.sumX2Y += x * x * y
  }
  project(x) {
    var det =
      this.count * this.sumX2 * this.sumX4 -
      this.count * this.sumX3 * this.sumX3 -
      this.sumX * this.sumX * this.sumX4 +
      2 * this.sumX * this.sumX2 * this.sumX3 -
      this.sumX2 * this.sumX2 * this.sumX2
    var offset =
      this.sumX * this.sumX2Y * this.sumX3 -
      this.sumX * this.sumX4 * this.sumXY -
      this.sumX2 * this.sumX2 * this.sumX2Y +
      this.sumX2 * this.sumX3 * this.sumXY +
      this.sumX2 * this.sumX4 * this.sumY -
      this.sumX3 * this.sumX3 * this.sumY
    var scale =
      -this.count * this.sumX2Y * this.sumX3 +
      this.count * this.sumX4 * this.sumXY +
      this.sumX * this.sumX2 * this.sumX2Y -
      this.sumX * this.sumX4 * this.sumY -
      this.sumX2 * this.sumX2 * this.sumXY +
      this.sumX2 * this.sumX3 * this.sumY
    var accel =
      this.sumY * this.sumX * this.sumX3 -
      this.sumY * this.sumX2 * this.sumX2 -
      this.sumXY * this.count * this.sumX3 +
      this.sumXY * this.sumX2 * this.sumX -
      this.sumX2Y * this.sumX * this.sumX +
      this.sumX2Y * this.count * this.sumX2
    return (offset + x * scale + x * x * accel) / det
  }
}

export { LineFitter, SquareFitter }
