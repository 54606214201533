<template>
  <ul>
    <li>{{ calcMonthSum() }}</li>
  </ul>
  <table class="table-auto w-full">
    <thead>
      <tr>
        <th>KW</th>
        <th>Datum</th>
        <th>Minuten</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in userTimesDateTimeRangeOutput"
        :key="item.date"
        :class="buildTableRowClass(item)"
      >
        <td class="align-top">
          <template v-if="index == 0 || item.weekday == 1">
            {{ item.weekNumber }}: {{ formatCalcWeekSums(item)[0] }}<br />
            {{ formatCalcWeekSums(item)[1] }}
            <!-- {{
              (
                userTimesDateWeekNumberSums.find(
                  (e) => e.kw == item.weekNumber
                ).kwSum / 60
              ).toFixed(2)
            }}
            ({{
              userTimesDateWeekNumberSums.find(
                (e) => e.kw == item.weekNumber
              ).planKwSum / 60
            }}) -->
          </template>
        </td>
        <td class="align-top">
          {{ item.localDateWithWeekday }}<br />
          <template v-if="item.dateTimeObj.length > 0">
            {{ minToHourStr(calcDelta(item).sumDayInMinutes) }}
            ({{ minToHourStr(calcDelta(item).planDayInMinutes) }}) [{{
              calcDelta(item).isPositiv ? "" : "-"
            }}{{ minToHourStr(calcDelta(item).deltaInMinutes) }}]
            <br />
          </template>
          {{ item.specialDay.title }}
        </td>
        <td class="align-top">
          <div
            v-if="item.dateTimeObj.concat(item.break.dateTimeObj).length > 0"
            class="card"
          >
            <ul>
              <li
                v-for="dtoi in item.dateTimeObj.concat(item.break.dateTimeObj)"
                :key="dtoi.userTimeId"
              >
                <table class="w-full">
                  <tr>
                    <td class="align-top">
                      <!-- <IconSelector class="text-zinc-500" :iconText="dtoi.dateTypeId" /> -->
                      <IconSelector
                        class="text-zinc-500"
                        :iconText="getDateType(dtoi.dateTypeId).icon.iconTitle"
                      />
                    </td>
                    <td class="align-top">
                      {{ dtoi.startTime }} - {{ dtoi.endTime }}<br />
                    </td>
                    <td class="align-top">
                      {{ minToHourStr(dtoi.durationInMinutes) }}<br />
                      <!-- {{ getDateType(dtoi.dateTypeId).icon.iconTitle }} -->
                      <!-- <br />{{ dtoi.userTimeId }} -->
                    </td>
                    <!-- <td class="align-top text-right pr-1">
                      <button
                        :class="
                          forDelete == null || forDelete.id != dtoi.userTimeId ? 'hover:bg-blue-500 text-blue-700 hover:text-blue-500 border-blue-500' : 'hover:bg-red-500 text-red-700 hover:text-red-500 border-red-500'
                        "
                        class="font-semibold bg-transparentpy-0 px-2 border hover:border-transparent rounded"

                        clasds="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-blue-500 py-0 px-2 border border-blue-500 hover:border-transparent rounded"
                        @click="deleteUserTimeBtn({ id: dtoi.userTimeId })">
                        <IconSelector iconText="trashCanOutline" />
                      </button>
                    </td> -->
                  </tr>
                </table>
                <!-- <hr v-if="item.dateTimeObj.concat(item.break.dateTimeObj) " /> -->
                <!-- <hr v-if="index < item.dateTimeObj.concat(item.break.dateTimeObj).length - 1" /> -->
              </li>
              <!-- <li>{{ item.break }}</li> -->
              <!-- <li v-for="(val, index) in item.break.dateTimeObj" :key="index">
                <pre>{{ val }}</pre>
              </li> -->
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
// import TrashCanOutlineIcon from "./combinedIcons/TrashCanOutlineIcon.vue";
import IconSelector from "./combinedIcons/IconSelector.vue"

export default {
  components: {
    // TrashCanOutlineIcon,
    IconSelector,
  },
  data() {
    return {
      forDelete: null,
    }
  },
  props: {
    userTimesDateWeekNumberSums: Array,
    userTimesDateTimeRangeOutput: Array,
    perDay: Number,
  },
  computed: {
    ...mapGetters("defaults", {
      getDateTypes: "getDateTypes",
    }),
  },
  methods: {
    ...mapActions("users", {
      deleteUserTime: "deleteUserTime",
    }),
    minToHourStr(minutes) {
      return new Date(minutes * 60 * 1000).toISOString().slice(11, 16)
    },
    calcMonthSum() {
      let retElement = {
        ist: 0,
        plan: 0,
        delta: 0,
        sumStart: 0,
      }
      this.userTimesDateTimeRangeOutput.forEach((item, index) => {
        if (index == 0 || item.weekday == 1) {
          const { ist, plan, delta } = this.calcWeekSums(item)
          retElement.ist += parseFloat(ist)
          retElement.plan += parseFloat(plan)
          retElement.delta += parseFloat(delta)
        }
      })
      return {
        ist: retElement.ist.toFixed(2),
        plan: retElement.plan.toFixed(2),
        delta: retElement.delta.toFixed(2),
      }
    },
    formatCalcWeekSums(item) {
      const { ist, plan, delta, minStartTime, maxEndTime } =
        this.calcWeekSums(item)
      return [
        ist + " (" + plan + ") [" + delta + "]",
        " | " +
          minStartTime?.toFixed(2) +
          " :: " +
          maxEndTime.toFixed(2) +
          " |",
      ]
    },
    calcWeekSums(item) {
      const element = this.userTimesDateWeekNumberSums.find(
        (e) => e.kw == item.weekNumber
      )
      const ist = (element.kwSum / 60).toFixed(2)
      const plan = (element.planKwSum / 60).toFixed(2)
      const delta = (ist - plan).toFixed(2)
      // const minStartTimes = (element.minStartTimes).toFixed(2)
      // const maxEndTimes = (element.maxEndTimes).toFixed(2)
      const minStartTime = Math.min(
        ...(element.minStartTimes.length > 0 ? element.minStartTimes : [0])
      )
      const maxEndTime = Math.max(
        ...(element.maxEndTimes.length > 0 ? element.maxEndTimes : [0])
      )

      return { ist, plan, delta, minStartTime, maxEndTime }
    },
    calcDelta(item) {
      const sumDayInMinutes = item.dateTimeObj
        .map((e) => e.durationInMinutes)
        .reduce((a, b) => a + b, 0)
      const sumBreakDayInMinutes = item.break.dateTimeObj
        .map((e) => e.durationInMinutes)
        .reduce((a, b) => a + b, 0)
      const planDayInMinutes = this.perDay * 60
      const deltaInMinutes =
        sumDayInMinutes - sumBreakDayInMinutes - planDayInMinutes
      const isPositiv = deltaInMinutes >= 0 ? true : false

      return {
        sumDayInMinutes: sumDayInMinutes - sumBreakDayInMinutes,
        planDayInMinutes,
        deltaInMinutes: Math.abs(deltaInMinutes),
        isPositiv,
      }
    },
    getDateType(dateTypeId) {
      return this.getDateTypes.find((e) => e.id == dateTypeId)
    },
    deleteUserTimeBtn(params) {
      console.log("params: ", params)
      console.log("forDelete: ", this.forDelete)
      if (this.forDelete == null || this.forDelete?.id != params.id) {
        this.forDelete = params
        console.log("A")
      } else {
        console.log("B")
        this.forDelete = null
        this.deleteUserTime(params)
      }
    },
    // async onDeleteUserTime(item) {
    //   await this.deleteUserTime({
    //     id: item.id,
    //     date: this.cardData.date,
    //     userId: this.userData.id,
    //   })
    // },
    buildTableRowClass(item) {
      return [0, 6].includes(item.weekday) || item.specialDay.title != null
        ? "bg-gray-300"
        : "even:bg-gray-50 odd:bg-white"
    },
  },
}
</script>
