<template>
  <div class="flex">
    <ChevronDoubleUp />
  </div>
</template>

<script>
import ChevronDoubleUp from "vue-material-design-icons/ChevronDoubleUp.vue"

export default {
  name: "ChevronDoubleUpIcon",
  components: {
    ChevronDoubleUp,
  },
}
</script>
