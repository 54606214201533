<template>
  <div>
    <div class="table w-full bg-white">
      <div class="table-row-group">
        <div class="table-row">
          <div class="table-cell">Start</div>
          <div class="table-cell">Ende</div>
          <div class="table-cell">{{ toHoursAndMinutes(buildSum) }}</div>
          <div class="table-cell"></div>
          <div class="table-cell"></div>
        </div>
      </div>
      <div
        v-for="item in userTimesData.concat(
          userBreakTimesData.map((e) => {
            return { ...e, date_type_title: 'break' }
          })
        )"
        :key="item.id"
        class="table-row"
      >
        <div class="table-cell">
          {{ getStartDate(item) }}
        </div>
        <div class="table-cell">
          <template v-if="item.end_date != null">
            {{ getEndDate(item) }}
          </template>
          <!-- <template v-else>
            <TimeRefresher class="border-none" :date="item.start_date" />
          </template> -->
        </div>
        <div class="table-cell">
          <template v-if="item.end_date != null">
            {{ toHoursAndMinutes(item.duration_in_minutes) }}
          </template>
          <template v-else>
            <TimeRefresher class="border-none" :date="item.start_date" />
          </template>
        </div>
        <div class="table-cell">
          <IconSelector
            :iconText="item.date_type_title"
            class="text-zinc-500 border-none w-12"
          />
        </div>
        <div class="table-cell">
          <div class="border-none">
            <button
              v-if="item.date_type_title != 'break'"
              :class="
                forDeleteTime == null || forDeleteTime?.id != item.id
                  ? 'hover:bg-blue-700 bg-blue-500'
                  : 'hover:bg-red-700 bg-red-500'
              "
              class="text-white font-bold py-0 px-2 rounded"
              @click="onDeleteUserTime(item)"
              @blur="forDeleteTime = null"
            >
              <IconSelector :iconText="'trashCanOutline'" />
            </button>
            <button
              v-else
              class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-2 rounded"
              @click="onDeleteUserBreakTime(item)"
              @blur="forDeleteTime = null"
            >
              <IconSelector :iconText="'trashCanOutline'" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import TimeRefresher from "./TimeRefresher.vue"
import IconSelector from "./combinedIcons/IconSelector.vue"

export default {
  name: "DataCardTable",
  components: {
    IconSelector,
    TimeRefresher,
  },
  props: {
    cardData: { id: "" },
    userData: { localDate: "" },
  },
  data() {
    return {
      forDeleteTime: null,
    }
  },
  computed: {
    ...mapGetters("users", {
      userTimesData: "userTimesData",
      userBreakTimesData: "userBreakTimesData",
      buildSum: "buildSum",
    }),
  },
  methods: {
    ...mapActions("users", {
      deleteUserTime: "deleteUserTime",
      deleteUserBreakTime: "deleteUserBreakTime",
    }),
    getStartDate(item) {
      if (item.start_date) {
        return new Date(item.start_date)
          .toLocaleTimeString("de-DE")
          .substring(0, 5)
      } else {
        return ""
      }
    },
    getEndDate(item) {
      if (item.end_date) {
        return new Date(item.end_date)
          .toLocaleTimeString("de-DE")
          .substring(0, 5)
      } else {
        return ""
      }
    },
    toHoursAndMinutes(totalMinutes) {
      if (totalMinutes == null) {
        return ""
      }

      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60

      const hoursStr = hours.toString().padStart(2, "0")
      const minutesStr = minutes.toString().padStart(2, "0")

      return `${hoursStr}:${minutesStr}`
    },
    async onDeleteUserTime(item) {
      if (this.forDeleteTime == null || this.forDeleteTime.id != item.id) {
        this.forDeleteTime = item
      } else {
        this.forDeleteTime = null
        await this.deleteUserTime({
          id: item.id,
          date: this.cardData.date,
          userId: this.userData.id,
        })
      }
    },
    async onDeleteUserBreakTime(item) {
      await this.deleteUserBreakTime({
        id: item.id,
        date: this.cardData.date,
        userId: this.userData.id,
      })
    },
  },
}
</script>
