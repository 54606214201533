<template>
  <div class="flex">
    <div class="w-1/2">&#189;</div>
    <div class="w-1/2">
      <PlaneTrain />
    </div>
  </div>
</template>

<script>
import PlaneTrain from "vue-material-design-icons/PlaneTrain.vue"

export default {
  name: "HalfVacationIcon",
  components: {
    PlaneTrain,
  },
}
</script>
