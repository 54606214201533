<template>
  <div class="flex">
    <Cogs />
  </div>
</template>

<script>
import Cogs from "vue-material-design-icons/Cogs.vue"

export default {
  name: "CogsIcon",
  components: {
    Cogs,
  },
}
</script>
