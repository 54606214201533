<template>
  <div class="flex">
    <PartyPopper />
  </div>
</template>

<script>
import PartyPopper from "vue-material-design-icons/PartyPopper.vue"

export default {
  name: "HolidayIcon",
  components: {
    PartyPopper,
  },
}
</script>
