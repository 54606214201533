<template>
  <div class="flex">
    <CoffeeOff />
  </div>
</template>

<script>
import CoffeeOff from "vue-material-design-icons/CoffeeOff.vue"

export default {
  name: "CoffeeOffIcon",
  components: {
    CoffeeOff,
  },
}
</script>
