<template>
  <div class="flex">
    <TrashCanOutline />
  </div>
</template>

<script>
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline.vue"

export default {
  name: "TrashCanOutlineIcon",
  components: {
    TrashCanOutline,
  },
}
</script>
