<template>
  <div class="flex">
    <ArrowBottomRight />
  </div>
</template>

<script>
import ArrowBottomRight from "vue-material-design-icons/ArrowBottomRight.vue"

export default {
  name: "ArrowBottomRightIcon",
  components: {
    ArrowBottomRight,
  },
}
</script>
