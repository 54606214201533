<template>
  <div class="flex">
    <AirplaneTakeoff />
  </div>
</template>

<script>
import AirplaneTakeoff from "vue-material-design-icons/AirplaneTakeoff.vue"

export default {
  name: "AirplaneTakeoffIcon",
  components: {
    AirplaneTakeoff,
  },
}
</script>
