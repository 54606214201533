<template>
  <div class="flex">
    <ArrowRight />
  </div>
</template>

<script>
import ArrowRight from "vue-material-design-icons/ArrowRight.vue"

export default {
  name: "ArrowRightIcon",
  components: {
    ArrowRight,
  },
}
</script>
