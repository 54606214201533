<template>
  <div class="flex">
    <ArrowUp />
  </div>
</template>

<script>
import ArrowUp from "vue-material-design-icons/ArrowUp.vue"

export default {
  name: "ArrowUpIcon",
  components: {
    ArrowUp,
  },
}
</script>
