<template>
  <div class="flex">
    <Coffee />
  </div>
</template>

<script>
import Coffee from "vue-material-design-icons/Coffee.vue"

export default {
  name: "CoffeeIcon",
  components: {
    Coffee,
  },
}
</script>
