import { createStore } from "vuex"
import authModule from "./modules/auth"
import defaultsModule from "./modules/defaults"
import usersModule from "./modules/users"
// import pictureModule from "./modules/picture"

const store = createStore({
  modules: {
    auth: authModule,
    defaults: defaultsModule,
    users: usersModule,
    // picture: pictureModule,
  },
})

export default store
