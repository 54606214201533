<template>
  <button
    :class="[classObject, defaultClasses]"
    :disabled="isDisabled"
    @click="onClick"
  >
    {{ title }}
    <IconSelector v-if="iconText" :iconText="iconText" />
  </button>
</template>

<script>
import IconSelector from "../combinedIcons/IconSelector.vue"

export default {
  name: "TimeTrackingButton",
  components: {
    IconSelector,
  },
  props: {
    classes: String,
    title: String,
    disabled: Boolean,
    iconText: String,
    bigButton: { type: Boolean, default: true },
  },
  data() {
    return {
      defaultClasses: "bg-blue-500 text-white font-bold rounded",
    }
  },
  computed: {
    isDisabled() {
      return this.disabled
    },
    isHovered() {
      return !this.disabled
    },
    classObject() {
      return {
        "opacity-50 cursor-not-allowed": this.isDisabled,
        "hover:bg-blue-700": this.isHovered,
        "py-2 px-4": this.bigButton,
        "p-1": !this.bigButton,
      }
    },
  },
  methods: {
    onClick(ev) {
      if (!this.disabled) {
        this.$emit("onButtonClick", ev)
      }
    },
  },
}
</script>
