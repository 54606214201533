import axios from "axios"

if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = "http://localhost:3030"
}

const state = () => ({
  userTimesDateTimeRange: [],
  userBreakTimesDateTimeRange: [],
  userTimesDataByWeeks: [],
  userBreakTimesDataByWeeks: [],
  userTimesData: [],
  userBreakTimesData: [],
  dateListData: [],
  selectedDate: null,
  userData: null,
})

const getters = {
  userTimesDateTimeRange(state) {
    return state.userTimesDateTimeRange
  },
  userBreakTimesDateTimeRange(state) {
    return state.userBreakTimesDateTimeRange
  },
  userTimesDataByWeeks(state) {
    return state.userTimesDataByWeeks
  },
  userBreakTimesDataByWeeks(state) {
    return state.userBreakTimesDataByWeeks
  },
  userTimesData(state) {
    return state.userTimesData
  },
  dateListData(state) {
    return state.dateListData
  },
  selectedDate(state) {
    return state.selectedDate
  },
  userData(state) {
    return state.userData
  },
  userBreakTimesData(state) {
    return state.userBreakTimesData
  },
  buildSum(state) {
    const sumDateTimeData = state.userTimesData.reduce(
      (sum, ne) => sum + ne.duration_in_minutes,
      0
    )
    const sumBrakeTimeData = state.userBreakTimesData.reduce(
      (sum, ne) => sum + ne.duration_in_minutes,
      0
    )
    return Math.max(0, sumDateTimeData - sumBrakeTimeData)
  },
}

const actions = {
  async getUserTimesDateTimeRange({ commit }, payload) {
    const response = await axios
      .post("/api/userTimes/dateRange", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setUserTimesDateTimeRange", response.data)
    }
  },
  async getUserBreakTimesDateTimeRange({ commit }, payload) {
    const response = await axios
      .post("/api/userBreakTimes/dateRange", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setUserBreakTimesDateTimeRange", response.data)
    }
  },
  async getUserTimeByDate({ commit }, payload) {
    const response = await axios
      .post("/api/userTimes", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setUserTimesData", response.data)
    }
  },
  async getUserBreakTimeByDate({ commit }, payload) {
    const response = await axios
      .post("/api/userBreakTimes", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setUserBreakTimesData", response.data)
    }
  },
  async getUserTimesSumWeeks({ commit }, payload) {
    const response = await axios
      .post("/api/userTimes/byWeeks", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setUserTimesByWeeks", response.data)
    }
  },
  async getUserBreakTimesSumWeeks({ commit }, payload) {
    const response = await axios
      .post("/api/userBreakTimes/byWeeks", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setUserBreakTimesByWeeks", response.data)
    }
  },
  async getDateListData({ commit }) {
    const response = await axios
      .get("/api/dateInterval", {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setDateListData", response.data)
    }
  },
  async getUserData({ commit }, payload) {
    const response = await axios
      .get("/api/users/" + payload.userId, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      commit("setUserData", response.data)
    }
  },
  // async refreshUserData({ commit }, payload) {
  //   const response = await axios
  //     .get("/api/users/" + payload, {
  //       withCredentials: false,
  //       credentials: "include",
  //       headers: {
  //         "x-access-token": localStorage.getItem("access_token"),
  //       },
  //     })
  //     .catch((err) => {
  //       console.log('err:', err);
  //     })

  //   if (response && response.data) {
  //     commit("setUserData", response.data);
  //   }
  // },
  async deleteUserTime({ dispatch }, payload) {
    const response = await axios
      .delete("/api/userTimes/" + payload.id, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      dispatch("getUserTimeByDate", {
        date: payload.date,
        userId: payload.userId,
      })
    }
  },
  async deleteUserBreakTime({ dispatch }, payload) {
    const response = await axios
      .delete("/api/userBreakTimes/" + payload.id, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response && response.data) {
      dispatch("getUserBreakTimeByDate", {
        date: payload.date,
        userId: payload.userId,
      })
    }
  },
  async setUserStart({ dispatch }, payload) {
    const response = await axios
      .post("/api/userTimes/start", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response) {
      dispatch("getUserTimeByDate", {
        date: payload.date,
        userId: payload.userId,
      })
    }
  },
  async setUserEnd({ dispatch }, payload) {
    const response = await axios
      .post("/api/userTimes/end", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response) {
      dispatch("getUserTimeByDate", {
        date: payload.date,
        userId: payload.userId,
      })
    }
  },
  async setUserBreakStart({ dispatch }, payload) {
    const response = await axios
      .post("/api/userBreakTimes/start", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response) {
      dispatch("getUserBreakTimeByDate", {
        date: payload.date,
        userId: payload.userId,
      })
    }
  },
  async setUserBreakEnd({ dispatch }, payload) {
    const response = await axios
      .post("/api/userBreakTimes/end", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response) {
      dispatch("getUserBreakTimeByDate", {
        date: payload.date,
        userId: payload.userId,
      })
    }
  },
  async setUserSettings({ dispatch }, payload) {
    const response = await axios
      .post("/api/users/settings/", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log("err:", err)
      })

    if (response) {
      dispatch("getUserBreakTimeByDate", {
        date: payload.date,
        userId: payload.userId,
      })
    }
  },
}

const mutations = {
  setUserTimesDateTimeRange(state, data) {
    state.userTimesDateTimeRange = data
  },
  setUserBreakTimesDateTimeRange(state, data) {
    state.userBreakTimesDateTimeRange = data
  },
  setUserTimesData(state, data) {
    state.userTimesData = data
  },
  setUserBreakTimesData(state, data) {
    state.userBreakTimesData = data
  },
  setDateListData(state, data) {
    state.dateListData = data
  },
  setSelectedDate(state, data) {
    state.selectedDate = data
  },
  setUserData(state, data) {
    state.userData = data
  },
  setUserTimesByWeeks(state, data) {
    state.userTimesDataByWeeks = data
  },
  setUserBreakTimesByWeeks(state, data) {
    state.userBreakTimesDataByWeeks = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
