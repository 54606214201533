<template>
  <div class="flex">
    <BriefcasePlusIcon />
  </div>
</template>

<script>
import BriefcasePlusIcon from "vue-material-design-icons/BriefcasePlus.vue"

export default {
  name: "WorkdayIcon",
  components: {
    BriefcasePlusIcon,
  },
}
</script>
