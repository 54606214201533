<template>
  <ChartLine :data="chartData.chartData" :options="chartData.chartOptions" />
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line as ChartLine } from "vue-chartjs"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: "LineChart",
  components: { ChartLine },
  props: {
    chartData: Object,
  },
}
</script>
