<template>
  <div class="flex justify-center overflow-x-auto">
    <nav ref="monthList" class="overflow-x-auto border-b text-sm flex">
      <div
        v-for="item in tabControls"
        :key="item.title"
        class="inline-block px-4 py-2"
        :class="
          selectedTab == item.title
            ? 'border-b-2 border-indigo-600 text-indigo-600 font-semibold'
            : 'text-gray-700 hover:text-black'
        "
        @click="setSelectedTab(item)"
      >
        {{ item.title }} {{ item.y }}
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MonthSelector",
  props: {
    tabControls: Array,
    selectedTab: String,
  },
  mounted() {
    this.scrollToLastTab()
  },
  methods: {
    setSelectedTab(item) {
      this.$emit("onSetSelectedTab", item)
    },
    scrollToLastTab() {
      const lastChildElement = this.$refs.monthList.lastElementChild
      lastChildElement?.scrollIntoView({
        behavior: "smooth",
      })
    },
  },
}
</script>
