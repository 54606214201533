<template>
  <div>
    <div class="flex justify-evenly">
      <CheckBox
        v-for="item in tabControlYears"
        :key="item.id"
        :item="item"
        @onToggleItem="toggleSelectedYear"
      />
    </div>
    <MonthSelector
      :tabControls="
        this.activeYears.length == 0
          ? tabControls
          : tabControls.filter((e) => this.activeYears.includes(e.y))
      "
      :selectedTab="selectedTab"
      @onSetSelectedTab="setSelectedTab"
    />
    <div class="h-[20rem]">
      <LineChart :chartData="lineChartData" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { IS08601WeekNo } from "../shared/dateHelper"
import LineChart from "./charts/LineChart.vue"
import MonthSelector from "./MonthSelector.vue"
import CheckBox from "./CheckBox.vue"

export default {
  computed: {
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
      getUserProfile: "getUserProfile",
    }),
    ...mapGetters("users", {
      userTimesDateTimeRange: "userTimesDateTimeRange",
      userBreakTimesDateTimeRange: "userBreakTimesDateTimeRange",
    }),
    tabControlYears() {
      const yearList = Array.from(new Set(this.tabControls.map((e) => e.y)))
      let retArr = []

      yearList.forEach((e, ind) => {
        retArr.push({
          id: ind,
          title: e,
          active: this.activeYears.includes(e),
        })
      })

      return retArr
    },
    tabControls() {
      let startDate = new Date("2023-10-01")
      let currentDate = new Date()

      let subDate = startDate
      let outArr = [
        {
          y: subDate.getFullYear(),
          m: subDate.getMonth(),
          title: subDate.toLocaleString("de-DE", { month: "long" }),
        },
      ]
      while (subDate < currentDate) {
        subDate.setMonth(subDate.getMonth() + 1)
        outArr.push({
          y: subDate.getFullYear(),
          m: subDate.getMonth(),
          title: subDate.toLocaleString("de-DE", { month: "long" }),
        })
      }
      return outArr
    },
    lineChartData() {
      const ret = this.userTimesDateTimeRangeOutput
      const labels = []
      const planData = []
      const istData = []
      const deltaData = []
      let deltaDataSum = 0
      ret.forEach((e) => {
        labels.push(e.localDateWithWeekday)
        let planSum = 0
        if ([1, 2, 3, 4, 5].includes(e.weekday)) {
          planSum = (38.5 * 60) / 5
        }

        planData.push((planSum / 60).toFixed(2))
        const breakSum = e.break.dateTimeObj
          .map((e) => e.durationInMinutes)
          .reduce((a, b) => a + b, 0)
        const istSum = e.dateTimeObj
          .map((e) => e.durationInMinutes)
          .reduce((a, b) => a + b, 0)
        const realSum = istSum - breakSum
        istData.push((realSum / 60).toFixed(2))
        const deltaSum = realSum - planSum

        if (realSum > 0) {
          deltaDataSum += deltaSum / 60
          deltaData.push((deltaSum / 60).toFixed(2))
        } else {
          deltaData.push(0)
        }
      })

      const deltaAvg = Array(deltaData.length).fill(
        deltaDataSum / deltaData.length
      )

      return {
        chartData: {
          labels: labels,
          datasets: [
            {
              label: "Plan",
              backgroundColor: "#f87979",
              data: planData,
              yAxisID: "y",
              cubicInterpolationMode: "monotone",
            },
            {
              label: "Ist",
              backgroundColor: "#0d6efd",
              data: istData,
              yAxisID: "y",
              cubicInterpolationMode: "monotone",
            },
            {
              label: "Delta",
              backgroundColor: "#008000",
              data: deltaData,
              yAxisID: "y1",
              cubicInterpolationMode: "monotone",
            },
            {
              label: "DeltaAVG",
              backgroundColor: "#adff2f",
              data: deltaAvg,
              yAxisID: "y1",
            },
          ],
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              display: true,
              position: "left",
            },
            y1: {
              type: "linear",
              display: true,
              position: "right",

              // grid line settings
              grid: {
                // drawOnChartArea: false, // only want the grid lines for one axis to show up
                color: function (context) {
                  if (context.tick.value == 0) {
                    return "#ff8c00"
                  }

                  return "#0000001a"
                },
              },
            },
          },
        },
      }
    },
  },
  components: {
    LineChart,
    MonthSelector,
    CheckBox,
  },
  data() {
    return {
      activeYears: [new Date().getFullYear()],
      userTimesDateTimeRangeOutput: [],
      userBreakTimesDateTimeRangeOutput: [],
      selectedTab: new Date().toLocaleString("de-DE", { month: "long" }),
      WEEKDAYS: [
        { short: "So", long: "Sonntag" },
        { short: "Mo", long: "Montag" },
        { short: "Di", long: "Dienstag" },
        { short: "Mi", long: "Mittwoch" },
        { short: "Do", long: "Donnerstag" },
        { short: "Fr", long: "Freitag" },
        { short: "Sa", long: "Samstag" },
      ],
      perDay: 0,
    }
  },
  created() {
    const tDate = new Date()
    this.callUserTimesDateTimeRange(
      this.buildDates(tDate.getFullYear(), tDate.getMonth())
    )
  },
  methods: {
    ...mapActions("users", {
      getUserTimesDateTimeRange: "getUserTimesDateTimeRange",
      getUserBreakTimesDateTimeRange: "getUserBreakTimesDateTimeRange",
    }),
    userTimesDateTimeRangeSummary() {
      this.userTimesDateWeekNumberSums = []
      this.userTimesDateTimeRangeOutput = []

      const workHoursPerWeek =
        this.getUserProfile.defaultWeekWorktimeInMinutes / 60
      const workDaysPerWeek = this.getUserProfile.defaultWeekWorkdays
      this.perDay = workHoursPerWeek / workDaysPerWeek

      this.userTimesDateTimeRange.forEach((entry) => {
        const ind = this.userTimesDateTimeRangeOutput.findIndex(
          (eo) => eo.date == entry.date_interval
        )
        if (ind == -1) {
          const dateEntry = new Date(entry.date_interval)
          const dateTimeObj = []
          let dateTimeSumObj = {
            durationInMinutes: 0,
          }
          if (entry.duration_in_minutes != null) {
            dateTimeSumObj.durationInMinutes = entry.duration_in_minutes
            dateTimeObj.push({
              userTimeId: entry.user_time_id,
              durationInMinutes: entry.duration_in_minutes,
              startTime: new Date(entry.start_date).toLocaleTimeString(
                "de-DE",
                { hour: "2-digit", minute: "2-digit" }
              ),
              endTime: new Date(entry.end_date).toLocaleTimeString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
              }),
              dateTypeId: entry.date_type_id,
            })
          }

          this.userTimesDateTimeRangeOutput.push({
            date: entry.date_interval,
            localDate: dateEntry.toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            localDateWithWeekday: dateEntry.toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              weekday: "short",
            }),
            weekday: dateEntry.getDay(),
            weekdayObj: this.WEEKDAYS[dateEntry.getDay()],
            weekNumber: IS08601WeekNo(dateEntry),
            dateTimeObj,
            dateTimeSumObj,
            specialDay: {
              title: entry.sd_title,
              dateTypeId: entry.sd_date_type_id,
              defaultDuration: 0,
            },
            break: {
              dateBreakTimeSumObj: {
                durationInMinutes: 0,
              },
              dateTimeObj: [],
            },
          })
        } else {
          if (entry.duration_in_minutes != null) {
            this.userTimesDateTimeRangeOutput[
              ind
            ].dateTimeSumObj.durationInMinutes += entry.duration_in_minutes
            this.userTimesDateTimeRangeOutput[ind].dateTimeObj.push({
              userTimeId: entry.user_time_id,
              durationInMinutes: entry.duration_in_minutes,
              dateTypeId: entry.date_type_id,
              startTime: new Date(entry.start_date).toLocaleTimeString(
                "de-DE",
                { hour: "2-digit", minute: "2-digit" }
              ),
              endTime: new Date(entry.end_date).toLocaleTimeString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
              }),
            })
          }
        }
      })
      this.userBreakTimesDateTimeRange.forEach((entry) => {
        const ind = this.userTimesDateTimeRangeOutput.findIndex(
          (eo) => eo.date == entry.date_interval
        )
        if (ind != -1 && entry.duration_in_minutes != null) {
          this.userTimesDateTimeRangeOutput[
            ind
          ].break.dateBreakTimeSumObj.durationInMinutes +=
            entry.duration_in_minutes
          this.userTimesDateTimeRangeOutput[ind].break.dateTimeObj.push({
            userTimeId: entry.user_time_id,
            durationInMinutes: entry.duration_in_minutes,
            dateTypeId: entry.date_type_id,
            startTime: new Date(entry.start_date).toLocaleTimeString("de-DE", {
              hour: "2-digit",
              minute: "2-digit",
            }),
            endTime: new Date(entry.end_date).toLocaleTimeString("de-DE", {
              hour: "2-digit",
              minute: "2-digit",
            }),
          })
        }
      })
    },
    toggleSelectedYear(item) {
      const year = item.title

      if (this.activeYears.includes(year)) {
        this.activeYears = this.activeYears.filter((i) => i !== year)
      } else {
        this.activeYears = [...this.activeYears, year]
      }
    },
    buildDates(oriY, oriM) {
      let date = new Date(oriY, oriM + 1, 0)
      const d = date.getDate()
      const m = date.getMonth() + 1
      const yearStr = date.getFullYear()
      const ddStr = d < 10 ? "0" + d.toString() : d.toString()
      const mmStr = m < 10 ? "0" + m.toString() : m.toString()

      return {
        endDateStr: yearStr + "-" + mmStr + "-" + ddStr,
        startDateStr: yearStr + "-" + mmStr + "-01",
      }
    },
    setSelectedTab(tab) {
      this.selectedTab = tab.title
      this.callUserTimesDateTimeRange(this.buildDates(tab.y, tab.m))
    },
    async callUserTimesDateTimeRange(buildedDate) {
      await this.getUserBreakTimesDateTimeRange({
        startDate: buildedDate.startDateStr,
        endDate: buildedDate.endDateStr,
        userId: this.gettersAuthData.userId,
      })
      await this.getUserTimesDateTimeRange({
        startDate: buildedDate.startDateStr,
        endDate: buildedDate.endDateStr,
        userId: this.gettersAuthData.userId,
      })

      this.userTimesDateTimeRangeSummary()
    },
  },
}
</script>
