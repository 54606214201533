<template>
  <div class="flex">
    <AirplaneClock />
  </div>
</template>

<script>
import AirplaneClock from "vue-material-design-icons/AirplaneClock.vue"

export default {
  name: "AirplaneClockIcon",
  components: {
    AirplaneClock,
  },
}
</script>
