<template>
  <div class="flex">
    <PlaneTrain />
  </div>
</template>

<script>
import PlaneTrain from "vue-material-design-icons/PlaneTrain.vue"

export default {
  name: "VacationIcon",
  components: {
    PlaneTrain,
  },
}
</script>
