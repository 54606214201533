<template>
  <div class="flex">
    <AirplaneLanding />
  </div>
</template>

<script>
import AirplaneLanding from "vue-material-design-icons/AirplaneLanding.vue"

export default {
  name: "AirplaneLandingIcon",
  components: {
    AirplaneLanding,
  },
}
</script>
