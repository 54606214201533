<template>
  <div class="flex">
    <ArrowBottomLeft />
  </div>
</template>

<script>
import ArrowBottomLeft from "vue-material-design-icons/ArrowBottomLeft.vue"

export default {
  name: "ArrowBottomLeftIcon",
  components: {
    ArrowBottomLeft,
  },
}
</script>
