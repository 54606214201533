<template>
  <div class="flex">
    <ArrowDown />
  </div>
</template>

<script>
import ArrowDown from "vue-material-design-icons/ArrowDown.vue"

export default {
  name: "ArrowDownIcon",
  components: {
    ArrowDown,
  },
}
</script>
