<template>
  <div class="flex">
    <CalendarToday />
  </div>
</template>

<script>
import CalendarToday from "vue-material-design-icons/CalendarToday.vue"

export default {
  name: "CalendarTodayIcon",
  components: {
    CalendarToday,
  },
}
</script>
