<template>
  <div v-if="getUserProfile.userRole == 'admin'">
    AdminArea
    {{ getUserProfile }}
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "AdminForm",
  computed: {
    ...mapGetters("auth", {
      getUserProfile: "getUserProfile",
    }),
  },
}
</script>
