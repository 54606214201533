<template>
  <div class="flex">
    <HospitalBoxOutline />
  </div>
</template>

<script>
import HospitalBoxOutline from "vue-material-design-icons/HospitalBoxOutline.vue"

export default {
  name: "IllnessIcon",
  components: {
    HospitalBoxOutline,
  },
}
</script>
