<template>
  <div>
    <component :is="iconList.find((e) => e.iconText == iconText).iconName" />
  </div>
</template>

<script>
import AirplaneClockIcon from "./AirplaneClockIcon.vue"
import AirplaneLandingIcon from "./AirplaneLandingIcon.vue"
import AirplaneTakeoffIcon from "./AirplaneTakeoffIcon.vue"
import ArrowBottomLeftIcon from "./ArrowBottomLeftIcon.vue"
import ArrowBottomRightIcon from "./ArrowBottomRightIcon.vue"
import ArrowDownIcon from "./ArrowDownIcon.vue"
import ArrowLeftIcon from "./ArrowLeftIcon.vue"
import ArrowRightIcon from "./ArrowRightIcon.vue"
import ArrowTopLeftIcon from "./ArrowTopLeftIcon.vue"
import ArrowTopRightIcon from "./ArrowTopRightIcon.vue"
import ArrowUpIcon from "./ArrowUpIcon.vue"
import CalendarArrowLeftIcon from "./CalendarArrowLeftIcon.vue"
import CalendarArrowRightIcon from "./CalendarArrowRightIcon.vue"
import CalendarTodayIcon from "./CalendarTodayIcon.vue"
import ChartTreeIcon from "./ChartTreeIcon.vue"
import ChevronDoubleDownIcon from "./ChevronDoubleDownIcon.vue"
import ChevronDoubleUpIcon from "./ChevronDoubleUpIcon.vue"
import CoffeeIcon from "./CoffeeIcon.vue"
import CoffeeOffIcon from "./CoffeeOffIcon.vue"
import CogsIcon from "./CogsIcon.vue"
import FinanceIcon from "./FinanceIcon.vue"
import HalfHolidayIcon from "./HalfHolidayIcon.vue"
import HalfVacationIcon from "./HalfVacationIcon.vue"
import HalfWorkdayIcon from "./HalfWorkdayIcon.vue"
import HolidayIcon from "./HolidayIcon.vue"
import HomeOutlineIcon from "./HomeOutlineIcon.vue"
import IllnessIcon from "./IllnessIcon.vue"
import LogoutIcon from "./LogoutIcon.vue"
import LogoutVariantIcon from "./LogoutVariantIcon.vue"
import MagnifyMinusOutlineIcon from "./MagnifyMinusOutlineIcon.vue"
import MagnifyPlusOutlineIcon from "./MagnifyPlusOutlineIcon.vue"
import TrashCanOutlineIcon from "./TrashCanOutlineIcon.vue"
import VacationIcon from "./VacationIcon.vue"
import ViewDashboardVariantOutlineIcon from "./ViewDashboardVariantOutlineIcon.vue"
import WorkdayIcon from "./WorkdayIcon.vue"
import WorkdayPlusIcon from "./WorkdayPlusIcon.vue"

export default {
  props: {
    iconText: String,
  },
  components: {
    AirplaneClockIcon,
    AirplaneLandingIcon,
    AirplaneTakeoffIcon,
    ArrowBottomLeftIcon,
    ArrowBottomRightIcon,
    ArrowDownIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ArrowTopLeftIcon,
    ArrowTopRightIcon,
    ArrowUpIcon,
    CalendarArrowLeftIcon,
    CalendarArrowRightIcon,
    CalendarTodayIcon,
    ChartTreeIcon,
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
    CoffeeIcon,
    CoffeeOffIcon,
    CogsIcon,
    FinanceIcon,
    HalfHolidayIcon,
    HalfVacationIcon,
    HalfWorkdayIcon,
    HolidayIcon,
    HomeOutlineIcon,
    IllnessIcon,
    LogoutIcon,
    LogoutVariantIcon,
    MagnifyMinusOutlineIcon,
    MagnifyPlusOutlineIcon,
    TrashCanOutlineIcon,
    VacationIcon,
    ViewDashboardVariantOutlineIcon,
    WorkdayIcon,
    WorkdayPlusIcon,
  },
  data() {
    return {
      iconList: [
        { iconName: "airplaneClockIcon", iconText: "airplaneClock" },
        { iconName: "airplaneLandingIcon", iconText: "airplaneLanding" },
        { iconName: "airplaneTakeoffIcon", iconText: "airplaneTakeoff" },
        { iconName: "arrowBottomLeftIcon", iconText: "arrowBottomLeft" },
        { iconName: "arrowBottomRightIcon", iconText: "arrowBottomRight" },
        { iconName: "arrowDownIcon", iconText: "arrowDown" },
        { iconName: "arrowLeftIcon", iconText: "arrowLeft" },
        { iconName: "arrowRightIcon", iconText: "arrowRight" },
        { iconName: "arrowTopLeftIcon", iconText: "arrowTopLeft" },
        { iconName: "arrowTopRightIcon", iconText: "arrowTopRight" },
        { iconName: "arrowUpIcon", iconText: "arrowUp" },
        { iconName: "calendarArrowLeftIcon", iconText: "calendarArrowLeft" },
        { iconName: "calendarArrowRightIcon", iconText: "calendarArrowRight" },
        { iconName: "calendarTodayIcon", iconText: "calendarToday" },
        { iconName: "chartTreeIcon", iconText: "chartTree" },
        { iconName: "chevronDoubleDownIcon", iconText: "chevronDoubleDown" },
        { iconName: "chevronDoubleUpIcon", iconText: "chevronDoubleUp" },
        { iconName: "coffeeIcon", iconText: "break" },
        { iconName: "coffeeIcon", iconText: "coffee" },
        { iconName: "coffeeOffIcon", iconText: "coffeeOff" },
        { iconName: "cogsIcon", iconText: "cogs" },
        { iconName: "financeIcon", iconText: "finance" },
        { iconName: "halfHolidayIcon", iconText: "half_holiday" },
        { iconName: "halfHolidayIcon", iconText: "halfHoliday" },
        { iconName: "halfVacationIcon", iconText: "half_vacation" },
        { iconName: "halfVacationIcon", iconText: "halfVacation" },
        { iconName: "halfWorkdayIcon", iconText: "half_workday" },
        { iconName: "halfWorkdayIcon", iconText: "halfWorkday" },
        { iconName: "holidayIcon", iconText: "holiday" },
        { iconName: "homeOutlineIcon", iconText: "homeOutline" },
        { iconName: "illnessIcon", iconText: "illness" },
        { iconName: "logoutIcon", iconText: "logout" },
        { iconName: "logoutVariantIcon", iconText: "logoutVariant" },
        {
          iconName: "magnifyMinusOutlineIcon",
          iconText: "magnifyMinusOutline",
        },
        { iconName: "magnifyPlusOutlineIcon", iconText: "magnifyPlusOutline" },
        { iconName: "trashCanOutlineIcon", iconText: "trashCanOutline" },
        { iconName: "vacationIcon", iconText: "vacation" },
        {
          iconName: "viewDashboardVariantOutlineIcon",
          iconText: "viewDashboardVariantOutline",
        },
        { iconName: "workdayIcon", iconText: "workday" },
        { iconName: "workdayPlusIcon", iconText: "workdayPlus" },
      ],
    }
  },
}
</script>
