<template>
  <div class="flex">
    <ChevronDoubleDown />
  </div>
</template>

<script>
import ChevronDoubleDown from "vue-material-design-icons/ChevronDoubleDown.vue"

export default {
  name: "ChevronDoubleDownIcon",
  components: {
    ChevronDoubleDown,
  },
}
</script>
