<template>
  <SettingsCard
    :userId="getUserProfile.id"
    :availableLocales="availableLocales"
    :userSettings="userSetting"
    @updateUserSettings="updateUserSettings"
  />
</template>

<script>
// , mapActions
import { mapGetters } from "vuex"
import SettingsCard from "./SettingsCard.vue"
export default {
  data() {
    return {
      userSetting: null,
      // selectedLocale: { id: 1, title: 'de-DE', name: 'Deutsch' },
      availableLocales: [
        { id: 1, title: "de-DE", name: "Deutsch" },
        { id: 2, title: "en-EN", name: "English" },
      ],
      // weekdays: [
      //   { id: 0, selected: false, short: "So", long: "Sonntag", duration_in_minutes: 0 },
      //   { id: 1, selected: false, short: "Mo", long: "Montag", duration_in_minutes: 0 },
      //   { id: 2, selected: false, short: "Di", long: "Dienstag", duration_in_minutes: 0 },
      //   { id: 3, selected: false, short: "Mi", long: "Mittwoch", duration_in_minutes: 0 },
      //   { id: 4, selected: false, short: "Do", long: "Donnerstag", duration_in_minutes: 0 },
      //   { id: 5, selected: false, short: "Fr", long: "Freitag", duration_in_minutes: 0 },
      //   { id: 6, selected: false, short: "Sa", long: "Samstag", duration_in_minutes: 0 },
      // ],
    }
  },
  created() {
    this.userSetting = this.getUserProfile.settings
  },
  components: { SettingsCard },
  computed: {
    ...mapGetters("auth", {
      getUserProfile: "getUserProfile",
    }),
  },
  methods: {
    updateUserSettings(settings) {
      this.userSetting = settings
    },
  },
}
</script>
