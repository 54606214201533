<template>
  <div class="flex gap-2 justify-center">
    <TimeTrackingButton
      classs="mr-2"
      title="Start"
      :disabled="disabledStartBtn"
      @onButtonClick="onStartClick"
    />
    <TimeTrackingButton
      title="Ende"
      :disabled="disabledEndBtn"
      @onButtonClick="onEndClick"
    />
    <TimeTrackingCheckbox
      tooltipText="Als Reisezeit"
      iconText="airplaneClock"
    />
  </div>

  <div class="flex gap-2 mt-2 justify-center">
    <TimeTrackingButton
      title="customTime"
      @onButtonClick="onOpenCustomTime"
    />
    <input v-if="openCustomTime" type="time" v-model="customTime" />
  </div>

  <div class="flex gap-2 mt-2 justify-center">
    <TimeTrackingButton
      :disabled="disabledBreakStartBtn()"
      :iconText="'coffee'"
      @onButtonClick="onBreakStartClick"
    />

    <TimeTrackingButton
      :disabled="disabledBreakEndBtn()"
      :iconText="'coffeeOff'"
      @onButtonClick="onBreakEndClick"
    />
  </div>

  <div class="flex gap-2 mt-2 justify-center">
    <TimeTrackingButton
      :iconText="toggleButtons ? 'chevronDoubleUp' : 'chevronDoubleDown'"
      @onButtonClick="toggleButtons = !toggleButtons"
    />
  </div>

  <Transition :duration="550" name="nested">
    <div v-if="toggleButtons" class="mt-2">
      <div class="flex flex-wrap justify-center">
        <div class="flex flex-nowrap pb-2">
          <TimeTrackingButton
            class="ml-auto"
            :disabled="disabledStartBtn"
            :iconText="'illness'"
            @onButtonClick="addUserTimeSpecialDay('illness')"
          />

          <span class="w-2"></span>

          <TimeTrackingButton
            class="ml-auto"
            :disabled="disabledStartBtn"
            :iconText="'halfWorkday'"
            @onButtonClick="addUserTimeSpecialDay('half_workday')"
          />
        </div>
        <div class="w-2"></div>
        <div class="flex flex-nowrap pb-2">
          <TimeTrackingButton
            class="ml-auto"
            :disabled="disabledStartBtn"
            :iconText="'vacation'"
            @onButtonClick="addUserTimeSpecialDay('vacation')"
          />

          <span class="w-2"></span>

          <TimeTrackingButton
            class="ml-auto"
            :disabled="disabledStartBtn"
            :iconText="'halfVacation'"
            @onButtonClick="addUserTimeSpecialDay('half_vacation')"
          />
        </div>
        <div class="w-2"></div>
        <div class="flex flex-nowrap pb-2">
          <TimeTrackingButton
            class="ml-auto"
            :disabled="disabledStartBtn"
            :iconText="'holiday'"
            @onButtonClick="addUserTimeSpecialDay('holiday')"
          />

          <span class="w-2"></span>

          <TimeTrackingButton
            class="ml-auto"
            :disabled="disabledStartBtn"
            :iconText="'halfHoliday'"
            @onButtonClick="addUserTimeSpecialDay('half_holiday')"
          />
        </div>
      </div>

      <div class="flex flex-wrap justify-center">
        <div class="flex flex-nowrap pb-2">
          <TimeTrackingButton
            class="ml-auto"
            :disabled="disabledStartBtn"
            :iconText="'workdayPlus'"
            @onButtonClick="addUserTimeSpecialDay('workday_plus')"
          />
        </div>
      </div>

      <div class="flex flex-wrap justify-center">
        <div class="flex flex-nowrap pb-2">
          <TimeTrackingButton
            class="ml-auto"
            :disabled="disabledStartTravelTimeBtn"
            :iconText="'airplaneTakeoff'"
            @onButtonClick="addUserTimeSpecialDay('ignore')"
          />
        </div>
      </div>
    </div>
  </Transition>

  <div>
    Einträge {{ cardData.weekday.short }}, {{ cardData.localDate }} von
    {{ userData.first_name }}
  </div>

  <DataCardTable :cardData="cardData" :userData="userData"></DataCardTable>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import TimeTrackingButton from "./guiComponents/TimeTrackingButton.vue"
import DataCardTable from "./DataCardTable.vue"
import TimeTrackingCheckbox from "./guiComponents/TimeTrackingCheckbox.vue"

export default {
  name: "DateCard",
  components: {
    TimeTrackingButton,
    DataCardTable,
    TimeTrackingCheckbox,
  },
  props: {
    cardData: { id: "" },
    userData: { localDate: "" },
  },
  data() {
    return {
      toggleButtons: false,
      openCustomTime: false,
      customTime: new Date().toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      }),
      disabledStartTravelTimeBtn: false,
    }
  },
  computed: {
    ...mapGetters("defaults", {
      getDateTypes: "getDateTypes",
    }),
    // ...mapGetters("auth", {
    //   gettersAuthData: "getAuthData",
    // }),
    ...mapGetters("users", {
      userTimesData: "userTimesData",
      userBreakTimesData: "userBreakTimesData",
    }),
    disabledStartBtn() {
      return this.userTimesData != null
        ? this.userTimesData.some((e) => e.end_date == null)
        : false
    },
    disabledEndBtn() {
      return this.userTimesData != null
        ? this.userTimesData.every((e) => e.end_date != null)
        : true
    },
  },
  created() {
    this.callUserTimeByDate(this.userData.id, this.cardData.date)
    this.callUserBreakTimeByDate(this.userData.id, this.cardData.date)
  },
  methods: {
    ...mapActions("users", {
      getUserTimeByDate: "getUserTimeByDate",
      getUserBreakTimeByDate: "getUserBreakTimeByDate",
      setUserStart: "setUserStart",
      setUserEnd: "setUserEnd",
      setUserBreakStart: "setUserBreakStart",
      setUserBreakEnd: "setUserBreakEnd",
    }),
    async callUserTimeByDate(userDataId, cardDataDate) {
      await this.getUserTimeByDate({ date: cardDataDate, userId: userDataId })
    },
    async callUserBreakTimeByDate(userDataId, cardDataDate) {
      await this.getUserBreakTimeByDate({
        date: cardDataDate,
        userId: userDataId,
      })
    },
    onOpenCustomTime() {
      this.customTime = new Date().toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      })
      this.openCustomTime = !this.openCustomTime
    },
    addUserTimeSpecialDay(dateType) {
      if (dateType.startsWith("ignore")) {
        console.log("Ignore: ", dateType)
      } else {
        this.onStartClick(null, dateType)
      }
    },

    getDateType(dateTypeId) {
      const dateType = this.getDateTypes.find((e) => e.id == dateTypeId)
      return dateType ?? { id: "123", title: "NONE" }
    },
    onStartClick(_ev, dateTypeStr = "workday") {
      if (!this.disabledStartBtn) {
        const now = new Date()
        if (this.openCustomTime) {
          now.setHours(this.customTime.split(":")[0])
          now.setMinutes(this.customTime.split(":")[1])
        }
        let zz = new Date(this.cardData.date)
        const dateParts = {
          y: zz.getFullYear(),
          m: zz.getMonth(),
          d: zz.getDate(),
          h: now.getHours(),
          mm: now.getMinutes(),
        }
        let startDate = new Date(
          dateParts.y,
          dateParts.m,
          dateParts.d,
          dateParts.h,
          dateParts.mm
        )

        this.setUserStart({
          date: this.cardData.date,
          startDate: startDate,
          userId: this.userData.id,
          dateTypeStr: dateTypeStr,
        })
      }
    },
    onEndClick() {
      if (!this.disabledEndBtn) {
        const now = new Date()
        if (this.openCustomTime) {
          now.setHours(this.customTime.split(":")[0])
          now.setMinutes(this.customTime.split(":")[1])
        }
        let zz = new Date(this.cardData.date)
        const dateParts = {
          y: zz.getFullYear(),
          m: zz.getMonth(),
          d: zz.getDate(),
          h: now.getHours(),
          mm: now.getMinutes(),
        }
        let endDate = new Date(
          dateParts.y,
          dateParts.m,
          dateParts.d,
          dateParts.h,
          dateParts.mm
        )

        this.setUserEnd({
          date: this.cardData.date,
          endDate: endDate,
          userId: this.userData.id,
        })
      }
    },
    disabledBreakStartBtn() {
      return this.userBreakTimesData.some((e) => e.end_date == null)
    },
    disabledBreakEndBtn() {
      return this.userBreakTimesData.every((e) => e.end_date != null)
    },
    onBreakStartClick() {
      if (!this.disabledBreakStartBtn()) {
        const now = new Date()
        if (this.openCustomTime) {
          now.setHours(this.customTime.split(":")[0])
          now.setMinutes(this.customTime.split(":")[1])
        }
        let zz = new Date(this.cardData.date)
        const dateParts = {
          y: zz.getFullYear(),
          m: zz.getMonth(),
          d: zz.getDate(),
          h: now.getHours(),
          mm: now.getMinutes(),
        }
        let startDate = new Date(
          dateParts.y,
          dateParts.m,
          dateParts.d,
          dateParts.h,
          dateParts.mm
        )

        this.setUserBreakStart({
          date: this.cardData.date,
          startDate: startDate,
          userId: this.userData.id,
        })
      }
    },
    onBreakEndClick() {
      if (!this.disabledBreakEndBtn()) {
        const now = new Date()
        if (this.openCustomTime) {
          now.setHours(this.customTime.split(":")[0])
          now.setMinutes(this.customTime.split(":")[1])
        }
        let zz = new Date(this.cardData.date)
        const dateParts = {
          y: zz.getFullYear(),
          m: zz.getMonth(),
          d: zz.getDate(),
          h: now.getHours(),
          mm: now.getMinutes(),
        }
        let endDate = new Date(
          dateParts.y,
          dateParts.m,
          dateParts.d,
          dateParts.h,
          dateParts.mm
        )

        this.setUserBreakEnd({
          date: this.cardData.date,
          endDate: endDate,
          userId: this.userData.id,
        })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}

/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}

/* delay enter of nested element */
.nested-enter-active .inner {
  transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  /*
    Hack around a Chrome 96 bug in handling nested opacity transitions.
    This is not needed in other browsers or Chrome 99+ where the bug
    has been fixed.
  */
  opacity: 0.001;
}
</style>
