<template>
  <div class="p-2">
    <h1 class="">
      {{ msg }}
    </h1>
    <div class="pt-2 flex flex-wrap justify-center">
      <div class="flex flex-nowrap pb-2">
        <TimeTrackingButton
          :iconText="'calendarArrowLeft'"
          class="mr-auto"
          :big-button="false"
          @onButtonClick="setSelectedDateByDirection('dateBefore')"
        />
        <span class="w-2"></span>
        <TimeTrackingButton
          :iconText="'calendarArrowRight'"
          class="mr-auto"
          :big-button="false"
          @onButtonClick="setSelectedDateByDirection('dateNext')"
        />
        <span class="w-2"></span>
        <select :value="selectedDate" @change="onChange($event)">
          <option v-for="item in dateListData" :key="item.localDate">
            {{ item.localDate }}
          </option>
        </select>
        <span class="w-2"></span>
        <TimeTrackingButton
          :iconText="'calendarToday'"
          class="mr-auto"
          :big-button="false"
          @onButtonClick="setSelectedDateByDirection('today')"
        />
      </div>
    </div>
    <template v-if="userData">
      <p v-for="item in dateListData" :key="item.date">
        <DateCard
          v-if="item.localDate == selectedDate"
          :cardData="item"
          :userData="userData"
        />
      </p>
    </template>
  </div>
</template>

<script>
import DateCard from "./DateCard.vue"
import { mapGetters, mapActions, mapMutations } from "vuex"
import TimeTrackingButton from "./guiComponents/TimeTrackingButton.vue"

export default {
  data() {
    return {}
  },
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    DateCard,
    TimeTrackingButton,
  },
  computed: {
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
    }),
    ...mapGetters("users", {
      dateListData: "dateListData",
      selectedDate: "selectedDate",
      userData: "userData",
    }),
  },
  created() {
    this.fetchDateIntervalData()
    this.fetchUserData()
    this.setSelectedDate(this.prepareSelectedDate())
  },
  methods: {
    ...mapActions("users", {
      getDateListData: "getDateListData",
      getUserData: "getUserData",
    }),
    ...mapMutations("users", {
      setSelectedDate: "setSelectedDate",
    }),
    setSelectedDateByDirection(direction) {
      const actualSelectedIndex = this.dateListData.findIndex(
        (e) => e.localDate == this.selectedDate
      )
      switch (direction) {
        case "dateBefore":
          this.setSelectedDate(
            this.dateListData[Math.max(0, actualSelectedIndex - 1)].localDate
          )
          break
        case "dateNext":
          this.setSelectedDate(
            this.dateListData[
              Math.min(this.dateListData.length - 1, actualSelectedIndex + 1)
            ].localDate
          )
          break
        case "today":
          this.setSelectedDate(this.prepareSelectedDate())
          break
      }
    },
    onChange(event) {
      this.setSelectedDate(event.target.value)
    },
    prepareSelectedDate() {
      let nowDate = new Date(Date.now())
      nowDate.setHours(0, 0, 0, 0)

      return nowDate.toLocaleDateString("de-DE")
    },
    async fetchUserData() {
      await this.getUserData({ userId: this.gettersAuthData.userId })
    },
    async fetchDateIntervalData() {
      await this.getDateListData()
      // console.log("Da", this.selectedDate)
      if (this.selectedDate == null) {
        // console.log("Hier")
        this.setSelectedDate(this.prepareSelectedDate())
      }
    },
  },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
