<template>
  <div>
    <div>
      <button
        class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="onSaveSettings"
      >
        Save
      </button>
    </div>
    <div>
      us:
      <!-- <pre>{{ userSettings }}</pre> -->
    </div>
    <div>
      <ul>
        <li>
          Locale<br />
          <select v-model="selectedLocale">
            <option
              v-for="item in availableLocales"
              :key="item.id"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select>
        </li>
        <li>
          Workdays<br />
          <template v-for="item in weekdays" :key="item.id">
            <label :for="item.id">{{ item.long }}</label>
            <input :id="item.id" type="checkbox" v-model="item.selected" />
            <input
              v-model="item.duration_in_minutes"
              class="w-16 border-solid border-1 border-indigo-600"
              type="text"
              @keyup.prevent="onInput($event.target.value, item)"
            /><br />
            {{ item.duration_in_minutes }}<br />
          </template>
        </li>
        <li></li>
        <li>Hours per day</li>
        <li>Holidays</li>
        <li>Vactions?</li>
        <li>EMail</li>
        <li>PW-reset2</li>
      </ul>
    </div>
    <!-- <div>
      <pre>
        {{ buildResponse }}
      </pre>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  props: {
    userId: String,
    availableLocales: Array,
    userSettings: Object,
  },
  data() {
    return {}
  },
  computed: {
    weekdays() {
      return this.userSettings.weekdays
    },
    selectedLocale: {
      get() {
        return this.userSettings.locale
      },
      set(value) {
        this.$emit("updateUserSettings", {
          ...this.userSettings,
          locale: value,
        })
      },
    },
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
    }),
    buildResponse() {
      return {
        weekdays: this.weekdays,
        locale: this.selectedLocale,
      }
    },
  },
  methods: {
    ...mapActions("users", {
      setUserSettings: "setUserSettings",
    }),
    ...mapActions("auth", {
      userProfile: "userProfile",
    }),
    async onSaveSettings() {
      // console.log('onSaveSettings', this.buildResponse)
      // console.log('sdf', this.userId)
      await this.setUserSettings({
        userId: this.userId,
        settings: this.buildResponse,
      })
      await this.userProfile()
    },
    onInput(value, item) {
      // console.log("value:", value)
      let regExp = new RegExp(/^\d*\.?\d*$/)
      if (value.includes(",")) {
        value = value.replaceAll(",", ".")
      }
      value = value.trim()

      const splittedValue = value.split(".")
      if (splittedValue.length > 2) {
        value = [splittedValue[0], splittedValue[1]].join(".")
      }

      while (!regExp.test(value) && value.length > 0) {
        value = value.slice(0, -1)
      }
      if (regExp.test(value)) {
        item.duration_in_minutes = value
      }
    },
  },
}
</script>
