<template>
  <div class="text-orange-700">
    {{ calcedTime }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      interval: null,
      calcedTime: "",
    }
  },
  props: {
    date: Date,
  },
  mounted() {
    this.interval = setInterval(() => {
      this.calcTime()
    }, 1000)

    this.calcTime()
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    calcTime() {
      const itemDate = new Date(this.date)
      let actDate = new Date()
      actDate.setDate(itemDate.getDate())
      actDate.setMonth(itemDate.getMonth())
      actDate.setFullYear(itemDate.getFullYear())

      const diff = actDate.getTime() - itemDate.getTime()
      let msec = diff
      const hh = Math.floor(msec / 1000 / 60 / 60)
      msec -= hh * 1000 * 60 * 60
      const mm = Math.floor(msec / 1000 / 60)
      msec -= mm * 1000 * 60
      const ss = Math.floor(msec / 1000)
      msec -= ss * 1000
      // console.log("actDate", actDate)
      // console.log("itemDate", itemDate)
      this.calcedTime =
        hh.toString().padStart(2, "0") +
        ":" +
        mm.toString().padStart(2, "0") +
        ":" +
        ss.toString().padStart(2, "0")
    },
  },
}
</script>
